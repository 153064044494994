import React from 'react';
import styled from 'styled-components';
import {sizes} from '../../../styles/mediaQueries';
import useWindowDimensions from '../../common/hooks/useWindowDimensions';
import RouteLink from '../../app/components/RouteLink';
import {useRouting} from '../../../routing/utils/useRouting';

interface ComponentProps {
  smallFooter: boolean;
}

const Footer: React.FC<ComponentProps> = ({ smallFooter }) => {
  const { isMobile } = useWindowDimensions();
  const { routes } = useRouting();

  return (
    <SC.Footer className={''}>
      <div className={'footer-block-wrapper container'}>
        <div className={'footer-block-wrapper__side'}>
          <div className={'footer-block'}>
            <div className={'footer-block__title menu-hover-effect '}>Virtual Market s.r.o</div>
            <div className={'footer-block__text'}>
              <div>
                Dusíkova 896/7a
                <br /> 638 00 Brno
                <br /> Lesná
              </div>
            </div>
            <div className={'footer-block__small'}>
              IČ: 27723909 DIČ: CZ27723909
              <br /> Zapsáno v OR u městského soudu <br />v Brně ve složce C 54736/KSBR
            </div>
          </div>
          <div className={'footer-block'}>
            <a className={'footer-block__title menu-hover-effect '} href={'mailto:info@etyka.cz'}>
              info@etyka.cz
            </a>
          </div>
          <div className={'footer-block'}>
            <a className={'footer-block__title menu-hover-effect '} href={'tel:+420736102601'}>
              +420 736 102 601
            </a>
          </div>
        </div>
        <div className={'footer-block-wrapper__side links'}>
          <div className={'footer-block '}>
            <ul>
              <li>
                <RouteLink to={routes.services.url()} className={'bold'}>
                  Služby
                </RouteLink>
              </li>
              <li>
                <RouteLink to={routes.technology.url()} className={'bold'}>
                  Technologie
                </RouteLink>
              </li>
              <li>
                <RouteLink to={routes.projects.url()} className={'bold'}>
                  Případové studie
                </RouteLink>
              </li>
            </ul>
          </div>
          <div className={'footer-block'}>
            <ul>
              <li>
                <RouteLink to={routes.aboutUs.url()} className={'footer-block__title menu-hover-effect '}>
                  O nás
                </RouteLink>
              </li>
              <li>
                <RouteLink to={routes.jobs.url()} className={'footer-block__title menu-hover-effect '}>
                  Kariéra
                </RouteLink>
              </li>
              <li>
                <RouteLink to={routes.blogs.url()} className={'footer-block__title menu-hover-effect '}>
                  Blog
                </RouteLink>
              </li>
              <li>
                <RouteLink to={routes.contact.url()} className={'footer-block__title menu-hover-effect '}>
                  Kontakt
                </RouteLink>
              </li>
            </ul>
          </div>

          <div className={'footer-block'}>
            <ul>
              <li>
                <RouteLink to={routes.conditions.url()}>Obchodní podmínky</RouteLink>
              </li>
              <li>
                <RouteLink to={routes.gdpr.url()}>Ochrana osobních údajů</RouteLink>
              </li>
              <li>
                <RouteLink to={routes.cookies.url()}>Cookies</RouteLink>
              </li>
            </ul>
          </div>
          <div className={'footer-block'}>
            <div className={'footer-block__title menu-hover-effect '}>Sledujte nás</div>
            <RouteLink
              to={routes.links.facebook}
              external={true}
              target={'_blank'}
              className={'footer-block__text text text-facebook'}
            >
              <img loading="lazy" src={'/icons/facebook.png'} alt={'facebook'} />
              <div>Facebook</div>
            </RouteLink>
            <RouteLink
              to={routes.links.instragram}
              external={true}
              target={'_blank'}
              className={'footer-block__text text text-instagram'}
            >
              <img loading="lazy" src={'/icons/instagram.png'} alt={'instagram'} />
              <div>Instagram</div>
            </RouteLink>
          </div>
        </div>
      </div>

      <div className={'footer-block-wrapper-mobile container'}>
        <div className={'footer-block-wrapper-mobile__row'}>
          <a className={'footer-block-wrapper-mobile__bold'} href={'mailto:info@etyka.cz'}>
            info@etyka.cz
          </a>
        </div>
        <div className={'footer-block-wrapper-mobile__row'}>
          <a className={'footer-block-wrapper-mobile__bold'} href={'tel:+420736102601'}>
            +420 736 102 601
          </a>
        </div>
        <div className={'footer-block-wrapper-mobile__row'}>
          <div className={'footer-block'}>
            <div className={'footer-block__title menu-hover-effect '}>Virtual Market s.r.o</div>
            <div className={'footer-block__text'}>
              <div>
                Dusíkova 896/7a
                <br /> 638 00 Brno
                <br /> Lesná
              </div>
            </div>
            <div className={'footer-block__small'}>
              IČ: 27723909 DIČ: CZ27723909
              <br /> Zapsáno v OR u městského soudu <br />v Brně ve složce C 54736/KSBR
            </div>
          </div>
        </div>
        <div className={'footer-block-wrapper-mobile__phrase'}>piece of cake</div>
      </div>

      {(!smallFooter || isMobile) && (
        <div className={`footer-display-logo ${!isMobile && 'container'}`}>
          <div className={'footer-display-logo__text'}>
            <div className={'footer-display-logo__small-logo'}>
              piece
              <br /> of cake
            </div>
            <div className={'footer-display-logo__large-logo'}>
              etyk<span className={'alt-ss01'}>a</span>
            </div>
            <div className={'footer-display-logo__footer-information'}>
              <div className={'footer-display-logo__footer-information__company-wrapper'}>
                © 2024 Virtual Market s.r.o. <wbr /> Všechna práva vyhrazena
              </div>
              <div className={'footer-display-logo__footer-information__text-wrapper'}>
                Develop&nbsp;
                <RouteLink to={routes.links.etyka}>etyka.cz</RouteLink>
              </div>
            </div>
          </div>
          <div className={`footer-display-logo__sun`}></div>
        </div>
      )}

      {smallFooter && !isMobile && (
        <div className={`footer-display-logo-small container`}>
          <div className={'footer-display-logo-small__text'}>
            <div className={'footer-display-logo__small-logo'}>
              piece
              <br /> of cake
            </div>
            <div className={'footer-display-logo__footer-information'}>
              <div className={'footer-display-logo__footer-information__company-wrapper'}>
                © 2024 Virtual Market s.r.o. <wbr /> Všechna práva vyhrazena
              </div>
            </div>
          </div>
          <div className={'footer-display-logo-small__right'}>
            <div
              className={'footer-display-logo-small__author-text footer-display-logo__footer-information__text-wrapper'}
            >
              <div>
                Develop{' '}
                <span>
                  &nbsp;<RouteLink to={routes.links.etyka}>etyka.cz</RouteLink>
                </span>
              </div>
            </div>
            <div className={`footer-display-logo-small__sun`} />
          </div>
        </div>
      )}
    </SC.Footer>
  );
};

export default Footer;

export const SC = {
  Footer: styled.div`
    background-color: #f7f6f0;
    padding-top: 10rem;

    @media (max-width: ${sizes.mDevice}) {
      padding-top: 40px;
    }

    .footer-block-wrapper-mobile {
      display: none;
      @media (max-width: ${sizes.mDevice}) {
        display: block;
      }

      &__row {
        display: flex;
        justify-content: center;
      }

      &__bold {
        font: normal 950 14px/40px 'Area Normal Black';
      }

      &__phrase {
        text-align: center;
        font: normal 950 25px/110px 'Area Extended Extrablack';
        margin-bottom: 2rem;
      }

      .footer-block {
        text-align: center;
      }
    }

    //.menu-hover-effect {
    //  transition: border-color 0.3s, color 0.3s;
    //  border-bottom: 1px solid transparent;
    //  padding-bottom: 0.25rem;
    //
    //  &:hover {
    //    border-color: black;
    //  }
    //}

    .footer-block-wrapper {
      border-top: 1px solid black;
      padding-top: 5rem;
      display: flex;
      justify-content: space-between;

      @media (max-width: ${sizes.mDevice}) {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-top: 2rem;
      }

      &__side {
        display: flex;
        gap: 2.5rem;
        @media (max-width: ${sizes.mDevice}) {
          display: none;
        }

        &.links {
          .footer-block {
            width: 20rem;

            &:last-child {
              width: 10rem;
            }
          }
        }
      }
    }

    .footer-block {
      display: flex;
      flex-direction: column;
      max-width: 16rem;
      gap: 1.5rem;

      ul {
        margin-top: 1rem;

        li {
          a {
            text-align: left;
            font: normal normal 12px/25px 'Area Normal Medium';
            letter-spacing: 0;

            &.bold {
              font: normal normal 12px/25px 'Area Normal Black';
            }
          }
        }
      }

      &__title {
        font: normal 950 14px/40px 'Area Normal Black';
      }

      &__text {
        text-wrap: nowrap;
        overflow: visible;
        @media (max-width: ${sizes.sDevice}) {
          overflow: hidden;
        }

        &.columns {
          display: flex;
          flex-direction: column;
        }

        a {
          font: normal 12px/17px 'Area Normal Medium';
        }

        &.text {
          display: flex;

          &-facebook {
            gap: 0.25rem;
            margin-left: -0.35rem;

            img {
              width: 32px;
              height: 32px;
            }
          }

          &-instagram {
            gap: 0.5rem;

            img {
              width: 27px;
              height: 27px;
            }
          }

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }

      &__text_title {
        font: normal 12px/40px 'Area Normal Medium';
      }

      &__small {
        text-wrap: nowrap;
        font: normal 10px/13px 'Area Normal Medium';
      }

      &.text-services {
        max-width: 5rem;
      }
    }

    .footer-display-logo {
      position: relative;
      width: 100%;
      aspect-ratio: 2/1;
      overflow: hidden;
      z-index: 1;

      @media (max-width: ${sizes.mDevice}) {
        overflow: hidden;
      }

      &__text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
      }

      &__small-logo {
        text-align: left;
        font: normal 950 5rem 'Area Extended Extrablack';
        z-index: 999;
        width: 17vw;
        margin-left: 5vw;

        @media (max-width: ${sizes.lDevice}) {
          font-size: 3.8rem;
        }

        @media (max-width: ${sizes.mDevice}) {
          display: none;
        }
      }

      &__large-logo {
        text-align: center;
        font: normal 950 36.3rem 'Area Extended Extrablack';
        z-index: 999;
        margin-bottom: -5rem;

        @media (max-width: ${sizes.sDevice}) {
          font-size: 19.5rem;
        }

        @media (max-width: ${sizes.lDevice}) {
          font-size: 20.5rem;
        }

        @media (max-width: ${sizes.lDevice}) {
          font-size: 26.5rem;
        }

        @media (max-width: ${sizes.xsDevice}) {
          margin-bottom: 0;
          font: normal 950 20vw 'Area Extended Extrablack';
          padding-bottom: 15px;
        }
      }

      &__sun {
        width: 100%;
        background-color: #f8e800;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }

      &__footer-information {
        display: flex;
        justify-content: space-between;
        margin-top: -4rem;
        padding: 3vw;
        padding-top: 0;
        padding-bottom: 1.5rem;
        font: normal 14px/22px 'Area Normal Medium';

        @media (max-width: ${sizes.mDevice}) {
          font: normal 12px/20px 'Area Normal Medium';
          padding-left: 3rem;
          padding-right: 3rem;
          margin-top: 0;
          padding-bottom: 0.5rem;
          display: block;
          text-align: center;
        }

        &__company-wrapper {
          text-wrap: wrap;
          @media (max-width: ${sizes.mDevice}) {
            max-width: 100%;
            text-wrap: nowrap;
          }
        }

        &__text-wrapper {
          display: flex;
          gap: 4rem;

          @media (max-width: ${sizes.mDevice}) {
            display: block;
            margin-top: 20px;
          }

          a {
            text-decoration: underline;
            font: normal 12px/20px 'Area Normal Medium';
          }
        }
      }
    }

    .footer-display-logo-small {
      position: relative;
      width: 100%;
      overflow: hidden;
      z-index: 1;
      display: flex;
      justify-content: space-between;

      &__right {
        width: 50%;
        aspect-ratio: 2/1;
        position: relative;
        overflow: hidden;
      }

      &__sun {
        width: 100%;
        aspect-ratio: 1/1;
        background-color: #f8e800;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &__author-text {
        justify-content: center;
        position: absolute;
        bottom: 0;
        z-index: 3;
        width: 100%;
        margin-bottom: 3rem;
      }

      &__text {
        display: flex;
        justify-content: end;
        flex-direction: column;
        margin-bottom: 3rem;
        gap: 6rem;

        div {
          margin: 0;
          padding: 0;
        }
      }
    }
  `,
};
