import React, {FC} from 'react';
import {useConfiguration} from '../../../configuration/useConfiguration';

const DevBanner: FC = () => {
  const { environment } = useConfiguration();

  if (environment === 'PRODUCTION') {
    return <></>;
  }
  return <div className={'dev-banner-info p-1'}>Testovací prostředí - {environment}</div>;
};

export default DevBanner;
