import styled, {css} from 'styled-components';
import {sizes} from '../../../../styles/mediaQueries';

export const SC = {
  Header: styled.div<{
    $isIndex: boolean;
  }>(
    ({ theme, $isIndex }) => css`
      position: relative;

      //background-color: #F7F6F0;

      @media (max-width: ${sizes.sDevice}) {
        height: 16vh;
      }

      .menu-hover-effect {
        transition: border-color 0.3s, color 0.3s;
        border-bottom: 1px solid transparent;
        padding-bottom: 0.25rem;

        &:hover {
          border-color: black;
        }
      }

      .dev-banner-info {
        text-align: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #f1416c;
        color: white;
        font-size: 12px;
        z-index: 1080;
        padding: 1rem;
        font-weight: bolder;
      }

      .header {
        padding: 4rem 5rem 0 5rem;
        align-items: start;
        z-index: 25;
        position: relative;
        background-color: #f7f6f0;

        @media (max-width: ${sizes.sDevice}) {
          padding: 3rem 3rem;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 999;
          background-color: #ffffff;
          height: 11.4rem;
        }

        .mobile-nav {
          display: none;
          z-index: 999;

          &__text {
            display: flex;
            justify-content: center;
            flex-direction: column;
            font: normal 950 12px 'Area Normal Black';
            padding: 1rem;

            @media (max-width: ${sizes.xsDevice}) {
              font-size: 10px;
            }

            a {
              border: 1px solid black;
              border-radius: 8px;
              padding: 0.6rem 0.75rem;
            }
          }

          &__hamburger {
            width: 50px;
            height: 50px;

            &.cross {
              width: 50px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              img {
                height: 22px;
              }
            }
          }

          @media (max-width: ${sizes.xsDevice}) {
            display: flex;
          }
        }

        .nav {
          @media (max-width: ${sizes.xsDevice}) {
            display: none;
          }

          ul {
            li {
              display: inline-block;
              margin-left: 2rem;
              font-family: 'Area Normal Medium', serif;
              font-size: 16px;
              letter-spacing: 0;
              color: #000000;
              opacity: 1;

              &.fw-bolder {
                font-family: 'Area Normal Extrablack', serif;
                font-weight: 900;
              }
            }
          }
        }

        .logo-link {
          display: block;
          position: relative;
          height: ${$isIndex ? '12vh' : '120px'};
          @media (max-width: ${sizes.xsDevice}) {
            padding-top: 1.4rem;
            z-index: 999;
          }

          .logo {
            font-family: 'Area Extended Extrablack', serif;
            font-weight: 500;
            font-size: 48px;
            @media (max-width: ${sizes.xsDevice}) {
              font-size: 25px;
            }
          }

          .circle {
            padding-top: 2rem;
            display: block;
            -webkit-animation-play-state: paused;
            -moz-animation-play-state: paused;
            -o-animation-play-state: paused;
            animation-play-state: paused;

            @media (max-width: ${sizes.xsDevice}) {
              padding-top: 1.45rem;
              z-index: 999;
            }

            &:before {
              content: '';
              position: absolute;
              width: 60px;
              height: 30px;
              background: #f8e800;
              border-radius: 30px 30px 0 0;
              @media (max-width: ${sizes.xsDevice}) {
                width: 38px;
                height: 19px;
              }
            }

            &:after {
              content: '';
              position: absolute;
              border: 30px solid transparent;
              border-bottom-color: #f8e800;
              border-left-color: #f8e800;
              transform: rotate(135deg);
              border-radius: 50%;
              transition: transform 0.3s ease-in;
              @media (max-width: ${sizes.xsDevice}) {
                border: 19px solid transparent;
              }
            }
          }

          &:hover .circle::after {
            content: '';
            position: absolute;
            border-bottom-color: #f8e800;
            border-left-color: #f8e800;
            transform: rotate(-45deg);
          }
        }
      }

      .invert-white {
        filter: brightness(0) invert(1);
      }

      .black-background {
        background-color: #000000;
      }

      .mobile-sub-menu {
        background-color: #000000;
        color: white;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 90vh;

        &__links {
          text-align: right;
          padding: 10vh 4rem;

          .fw-bolder {
            a {
              font: normal normal 950 16px 'Area Normal Black' !important;
            }
          }

          li {
            margin-bottom: 3rem;
          }

          a {
            font: normal 16px 'Area Normal Medium';
            color: white;
          }
        }

        &__slogan {
          color: white;
          position: absolute;
          font: normal normal 950 19px 'Area Extended ExtraBlack' !important;
          bottom: 10rem;
          left: 3rem;
        }
      }

      .header-full-sticky {
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 20;
        background-color: #f7f6f0;
        padding-bottom: 1rem;
        padding-top: 3rem;

        @media (max-width: ${sizes.xsDevice}) {
          display: none;
        }

        .logo-holder {
          display: flex;
          gap: 1rem;
          font-family: 'Area Extended Extrablack', serif;
          font-weight: 500;
          font-size: 25px;

          :hover {
            .circle-block__rotor {
              transform: rotate(-180deg);
            }
          }
        }

        .circle-block {
          display: block;
          height: 3rem;
          position: relative;
          width: fit-content;
          margin-top: 0.5rem;

          &__circle {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: #f8e800;
          }

          &__circle-hinge {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            background-color: #f8e800;
            position: absolute;
            top: 0;
            left: 0;
          }

          &__hinge {
            height: 50%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;

            &.color {
              background-color: #f7f6f0;
            }

            &.transparent {
              background-color: transparent;
            }
          }

          &__rotor {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: rotate(0deg);
            transition: transform 0.2s ease-in;
            overflow: hidden;
            z-index: 25;

            &__half {
              height: 50%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              overflow: hidden;
            }
          }
        }
      }

      .show-value {
        z-index: 100 !important;
      }
    `
  ),
};
