import React, { useEffect, useRef, useState } from 'react';
import { SC } from './header.styled';
import DevBanner from '../../../common/components/devBanner';
import useWindowDimensions from '../../../common/hooks/useWindowDimensions';
import HeaderServices from './headerServices';
import RouteLink from '../../../app/components/RouteLink';
import { useRouting } from '../../../../routing/utils/useRouting';
import NavigationBar from './navigationBar';
import useOnClickOutside from '../../../../utils/hooks/useOnClickOutside';

interface Props {
  isIndex: boolean;
}

const HeaderWrapper: React.FC<Props> = ({ isIndex }) => {
  const { routes, router } = useRouting();

  const [showMenu, setShowMenu] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (!isMobile) {
      setShowMenu(false);
    }

    if (isMobile) {
      setShowServices(false);
    }
  }, [isMobile]);

  useEffect(() => {
    setShowMenu(false);
    setShowServices(false);
  }, [router.pathname]);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('scroll', isSticky);
    }

    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const header = document.getElementById('header-sticky');
    const scrollTop = window.scrollY;
    if (!header) {
      return;
    }
    scrollTop >= 20 ? header.classList.add('show-value') : header.classList.remove('show-value');
  };

  const boxRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(boxRef, () => setShowServices(false));

  return (
    <SC.Header $isIndex={isIndex} className={isIndex ? 'index' : undefined} ref={boxRef}>
      {showServices && <HeaderServices setShowServices={setShowServices} />}
      <DevBanner />
      <div className={`header displayFlex flexJustifyBetween ${showMenu && 'black-background'}`}>
        <div>
          <RouteLink to={routes.index.url()} className={`logo-link`} external={true}>
            <div className={`logo ${showMenu && 'invert-white'}`}>
              etyk<span className={'alt-ss01'}>a</span>
            </div>
            <div className={'circle'}></div>
          </RouteLink>
        </div>
        <NavigationBar setShowServices={() => setShowServices(!showServices)} />
        <div className={`mobile-nav`}>
          <div className={`mobile-nav__text ${showMenu && 'invert-white'}`}>
            <a href={'#contact'}>Kontaktujte nás</a>
          </div>
          <div
            className={`mobile-nav__hamburger ${showMenu && 'cross invert-white'}`}
            onClick={() => setShowMenu(!showMenu)}
          >
            {!showMenu && <img loading="lazy" src={'/icons/hamburger-menu.svg'} alt={'hamburger-menu'} />}
            {showMenu && <img loading="lazy" src={'/icons/cross.svg'} alt={'cross'} />}
          </div>
        </div>
        {showMenu && (
          <div className={'mobile-sub-menu'}>
            <div className={'mobile-sub-menu__links'}>
              <ul>
                <li className={'fw-bolder  menu-hover-effect'}>
                  <RouteLink to={routes.services.url()} className={'footer-block__title menu-hover-effect '}>
                    Služby
                  </RouteLink>
                </li>
                <li className={'fw-bolder  menu-hover-effect'}>
                  <RouteLink to={routes.technology.url()} className={'footer-block__title menu-hover-effect '}>
                    Technologie
                  </RouteLink>
                </li>
                <li className={'fw-bolder  menu-hover-effect'}>
                  <RouteLink to={routes.projects.url()} className={'footer-block__title menu-hover-effect '}>
                    Případové studie
                  </RouteLink>
                </li>
                <li className={'menu-hover-effect'}>
                  <RouteLink to={routes.aboutUs.url()} className={'footer-block__title menu-hover-effect '}>
                    O nás
                  </RouteLink>
                </li>
                <li className={'menu-hover-effect'}>
                  <RouteLink to={routes.blogs.url()} className={'footer-block__title menu-hover-effect '}>
                    Blog
                  </RouteLink>
                </li>
                <li className={'menu-hover-effect'}>
                  <RouteLink to={routes.jobs.url()} className={'footer-block__title menu-hover-effect '}>
                    Kariéra
                  </RouteLink>
                </li>
                <li className={'menu-hover-effect'}>
                  <RouteLink to={routes.contact.url()} className={'footer-block__title menu-hover-effect '}>
                    Kontakt
                  </RouteLink>
                </li>
                <li className={'fw-bolder menu-hover-effect'}>
                  <a href={'tel:+420736102601'}>+420 736 102 601</a>
                </li>
              </ul>
            </div>
            <div className={'mobile-sub-menu__slogan'}>
              piece
              <br />
              of cake
            </div>
          </div>
        )}
      </div>

      <div className={'header header-full-sticky'} id={'header-sticky'}>
        <div>
          <RouteLink to={routes.index.url()} className={`logo-holder`}>
            <div className={'circle-block'}>
              <div className={'circle-block__rotor'}>
                <div className={'circle-block__rotor__half'}>
                  <div className={'circle-block__circle-hinge'} />
                </div>
              </div>
              <div className={'circle-block__circle'} />
              <div className={'circle-block__hinge color'} />
            </div>
            <div className={`logo`}>
              etyk<span className={'alt-ss01'}>a</span>
            </div>
          </RouteLink>
        </div>
        <NavigationBar setShowServices={() => setShowServices(!showServices)} />
      </div>
    </SC.Header>
  );
};

export default HeaderWrapper;
