import React from 'react';
import RouteLink from '../../../app/components/RouteLink';
import { useRouting } from '../../../../routing/utils/useRouting';

interface Props {
  setShowServices: () => void;
}

const NavigationBar: React.FC<Props> = ({ setShowServices }) => {
  const { routes, router } = useRouting();

  return (
    <div className={'nav'}>
      <ul>
        <li className={'fw-bolder menu-hover-effect'}>
          <RouteLink to={routes.services.url()} onMouseOver={setShowServices} external={true}>
            Služby
          </RouteLink>
        </li>
        <li className={'fw-bolder  menu-hover-effect'}>
          <RouteLink to={routes.technology.url()} className={'footer-block__title menu-hover-effect '}>
            Technologie
          </RouteLink>
        </li>
        <li className={'fw-bolder  menu-hover-effect'}>
          <RouteLink to={routes.projects.url()} className={'footer-block__title menu-hover-effect '}>
            Případové studie
          </RouteLink>
        </li>
        <li className={'menu-hover-effect'}>
          <RouteLink to={routes.aboutUs.url()} className={'footer-block__title menu-hover-effect '}>
            O nás
          </RouteLink>
        </li>
        <li className={'menu-hover-effect'}>
          <RouteLink to={routes.blogs.url()} className={'footer-block__title menu-hover-effect '}>
            Blog
          </RouteLink>
        </li>
        <li className={'menu-hover-effect'}>
          <RouteLink to={routes.jobs.url()} className={'footer-block__title menu-hover-effect '}>
            Kariéra
          </RouteLink>
        </li>
        <li className={'menu-hover-effect'}>
          <RouteLink to={routes.contact.url()} className={'footer-block__title menu-hover-effect '}>
            Kontakt
          </RouteLink>
        </li>
        <li className={'menu-hover-effect'}>
          <a href={'tel:+420736102601'}>+420 736 102 601</a>
        </li>
      </ul>
    </div>
  );
};

export default NavigationBar;
