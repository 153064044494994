import styled, {css} from 'styled-components';

export const SC = {
  HeaderServices: styled.div(
    ({ theme }) => css`
      position: fixed;
      background-color: #f7f6f0;
      border: 1px solid #d5caca;
      border-radius: 5px;
      animation: move 0.2s ease forwards;
      opacity: 0;
      z-index: 9999;
      left: 0;
      right: 0;
      margin: auto;
      display: block;
      margin-top: 70px;
      width: 50vw;

      @keyframes move {
        to {
          opacity: 1;
        }
      }

      .services {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        padding: 4rem;

        .service {
          h3 {
            font: normal normal 950 17px/40px 'Area Extended ExtraBlack';
          }

          ul {
            li {
              padding-bottom: 1rem;

              a {
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    `
  ),
};
