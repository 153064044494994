import React from 'react';
import { SC } from './headerServices.styled';
import { useRouting } from '../../../../routing/utils/useRouting';
import RouteLink from '../../../app/components/RouteLink';
import { useSelector } from 'react-redux';
import { selectServiceList } from '../../../service/reducer/selectors';

interface ComponentProps {
  setShowServices: any;
}
const HeaderServices: React.FC<ComponentProps> = ({ setShowServices }) => {
  const { routes, router } = useRouting();
  const services = useSelector(selectServiceList);

  return (
    <SC.HeaderServices>
      <div className={'services'} onMouseLeave={() => setShowServices(false)}>
        {services &&
          services.content.map((service) => (
            <div className={'service'}>
              <h3>
                <RouteLink to={routes.service.url({ serviceSlug: service.url })}>{service.title}</RouteLink>
              </h3>
              <ul>
                {service.children.map((child) => (
                  <li key={'service-1-6'}>
                    <RouteLink to={routes.service.url({ serviceSlug: child.url })}>{child.name}</RouteLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </SC.HeaderServices>
  );
};

export default HeaderServices;
