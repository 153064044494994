import React from 'react';
import {SC} from './footer.styled';
import Footer from '../footer';

interface Props {
  isIndex: boolean;
  smallFooter: boolean;
}

const FooterWrapper: React.FC<Props> = ({ isIndex, smallFooter }) => {
  return (
    <SC.Footer>
      <Footer smallFooter={smallFooter} />
    </SC.Footer>
  );
};

export default FooterWrapper;
